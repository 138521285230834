import React, { useState, useEffect } from 'react';
import { Typography, Divider, List, Carousel, Tooltip, Avatar, Spin, Input } from 'antd';
import { FileAddTwoTone, FileTextTwoTone, DollarTwoTone, PlusSquareTwoTone, BuildTwoTone } from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';
import sunImage from './assets/sun.png'; // Path to your sun image
import moonImage from './assets/moon.png'; // Path to your moon image
import config from './Config';

const { Title, Text } = Typography;
const { Search } = Input;

const HomePage = () => {
  const [recentProjects, setRecentProjects] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [image, setImage] = useState(sunImage); // Default to sun image
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Determine the current hour
    const hour = new Date().getHours();
    if (hour >= 6 && hour < 18) {
      setImage(sunImage); // Set sun image for daytime
    } else {
      setImage(moonImage); // Set moon image for nighttime
    }

    // Fetch recent projects from the API
    fetch(`${config.apiUrl}/projects`) // Replace with your actual API endpoint
      .then(response => response.json())
      .then(data => {
        console.log('Fetched projects:', data); // Log the data fetched
        if (Array.isArray(data)) {
          setRecentProjects(data);
        } else {
          console.error('Unexpected response format:', data);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching projects:', error);
        setLoading(false);
      });
  }, []);

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const filteredProjects = recentProjects
    .filter(project => project.projectName.toLowerCase().includes(searchQuery.toLowerCase()))
    .slice(0, 5);

  return (
    <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={image} alt="time of day" style={{ width: 40, height: 40, marginRight: 10 }} />
        <Title level={2}>Welcome, User</Title>
      </div>
      <Divider />
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
      <Carousel arrows infinite={false} style={{ maxWidth:'350px', textAlign: 'center', margin: '0', borderRadius: '8px' }}>
        <div>
          <div style={{ padding: '20px', cursor: 'pointer' }} onClick={() => navigate('/projects/create')}>
            <FileAddTwoTone twoToneColor='#5b6d92' style={{ fontSize: '48px' }} />
            <Title level={4}>New Project</Title>
          </div>
        </div>
        <div>
          <div style={{ padding: '20px', cursor: 'pointer' }} onClick={() => navigate('/module')}>
            <BuildTwoTone twoToneColor='#5b6d92' style={{ fontSize: '48px' }} />
            <Title level={4}>New Module</Title>
          </div>
        </div>
        <div>
          <div style={{ padding: '20px', cursor: 'pointer' }} onClick={() => navigate('/pricelists')}>
            <DollarTwoTone twoToneColor='#5b6d92' style={{ fontSize: '48px' }} />
            <Title level={4}>Price Lists</Title>
          </div>
        </div>
        <div>
          <div style={{ padding: '20px', cursor: 'pointer' }} onClick={() => navigate('/bim')}>
            <PlusSquareTwoTone twoToneColor='#5b6d92' style={{ fontSize: '48px' }} />
            <Title level={4}>Import BIM</Title>
          </div>
        </div>
      </Carousel>
      </div>
      <Divider />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Title level={4}>Recent Projects</Title>
        <Search
          placeholder="Search projects"
          onSearch={handleSearch}
          style={{ width: 200 }}
        />
      </div>
      {loading ? (
        <Spin size="large" />
      ) : (
        <List
          itemLayout="horizontal"
          dataSource={filteredProjects}
          renderItem={item => {
            if (!item || typeof item !== 'object' || !item.projectName || !item.clientName) {
              console.error('Invalid item:', item);
              return null;
            }
            return (
              <List.Item>
                <List.Item.Meta
                  avatar={<FileTextTwoTone twoToneColor='#5b6d92' style={{ fontSize: '24px' }} />}
                  title={<Link to={`/projects/${item.projectId}`}>{item.projectName}</Link>}
                  description={<Text type="secondary" style={{ fontSize: '12px' }}>Client: {item.clientName}</Text>}
                />
                <div className="client-column">
                  <Avatar>{item.clientName[0]}</Avatar>
                  <div style={{ display: 'inline-block', marginLeft: 10 }}>
                    <Text strong>{item.clientName}</Text>
                  </div>
                </div>
              </List.Item>
            );
          }}
        />
      )}
    </div>
  );
};

export default HomePage;
