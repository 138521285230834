import React, { useState, useEffect } from 'react';
import { List, Button, Drawer, Form, Input, message, Row, Col, InputNumber, Typography, Divider } from 'antd';
import { EditOutlined, EyeOutlined, EyeInvisibleOutlined, ReloadOutlined, SaveOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from './Config';

const { Text } = Typography;

const ProjectExchangeRate = ({ projectId, exchangeRates, onRatesUpdated }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rates, setRates] = useState([...exchangeRates]);
  const [showRates, setShowRates] = useState(false); // Changed from true to false for hidden by default

  const baseURL = `${config.apiUrl}`;

  useEffect(() => {
    setRates([...exchangeRates]);
  }, [exchangeRates]);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const toggleRates = () => {
    setShowRates(!showRates);
  };

  const handleRateChange = (index, value) => {
    const newRates = [...rates];
    newRates[index] = { ...newRates[index], rate: value, inverseRate: (1 / value).toFixed(2) };
    setRates(newRates);
  };

  const handleInverseRateChange = (index, value) => {
    const newRates = [...rates];
    newRates[index] = { ...newRates[index], inverseRate: value, rate: (1 / value).toFixed(2) };
    setRates(newRates);
  };

  const onFinish = async () => {
    setLoading(true);
    try {
      await axios.put(`${baseURL}/projects/${projectId}/exchangerates`, rates);
      message.success('Exchange rates updated successfully');
      onClose();
      if (onRatesUpdated) {
        onRatesUpdated(rates);
      }
    } catch (error) {
      console.error('Error updating exchange rates:', error);
      message.error('Failed to update exchange rates. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const refreshRates = async () => {
    setLoading(true);
    try {
      const response = await axios.put(`${baseURL}/projects/${projectId}/exchangerates/refresh`);
      setRates(response.data);
      message.success('Exchange rates refreshed successfully');
    } catch (error) {
      console.error('Error refreshing exchange rates:', error);
      message.error('Failed to refresh exchange rates. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {showRates && (
        <List
          header={
            <Row style={{ width: '100%' }}>
              <Col span={8}><strong>Target </strong></Col>
              <Col span={8}><strong>Rate </strong></Col>
              <Col span={8}><strong>Inverse Rate</strong></Col>
            </Row>
          }
          bordered
          dataSource={rates}
          size='small'
          renderItem={(rate, index) => (
            <List.Item>
              <Row style={{ width: '100%' }}>
                <Col span={8}>{rate.targetCurrencyCode} ({rate.targetCurrencySymbol})</Col>
                <Col span={8}> {rate.currencySymbol}1 = {rate.targetCurrencySymbol}{parseFloat(rate.rate).toFixed(2)}</Col>
                <Col span={8}> {rate.targetCurrencySymbol}1 = {rate.currencySymbol}{parseFloat(rate.inverseRate).toFixed(2)} </Col>
              </Row>
            </List.Item>
          )}
        />
      )}
      <Button type="primary" onClick={showDrawer} style={{ marginTop: 16 }}>
        <EditOutlined /> Edit
      </Button>
      <Button type="primary" onClick={toggleRates} style={{ marginBottom: 16 }}>
        {showRates ? <EyeInvisibleOutlined /> : <EyeOutlined />} {showRates ? 'Hide' : 'Exchange Rates'}
      </Button>
      <Drawer
        title="Edit Exchange Rates"
        width={720}
        onClose={onClose}
        open={visible}
        style={{ paddingBottom: 80 }}
      >
        {/* Explanation of the Darwin Exchange Rate Engine feature */}
        <Typography.Paragraph style={{ marginBottom: 16 }}>
          Discover the power of the Darwin Exchange Rate Engine – your trusted partner for real-time, automated updates to over 160 global exchange rates and cryptocurrencies. Refresh anytime for the latest market data.
        </Typography.Paragraph>
        {/* Refresh button aligned to the right */}
        <Button type="default" onClick={refreshRates} style={{ marginBottom: 16, float: 'right' }}>
          <ReloadOutlined /> Refresh Exchange Rates
        </Button>
        <Divider/>
        <Form layout="vertical" onFinish={onFinish}>
          {rates.map((rate, index) => (
            <Row gutter={16} key={rate.exchangeRateId}>
              <Col span={12}>
                <Form.Item label={`${rate.currencySymbol}1 = (${rate.targetCurrencyCode})`} required>
                  <InputNumber
                    value={rate.rate}
                    onChange={(value) => handleRateChange(index, value)}
                    addonBefore={rate.targetCurrencySymbol}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={`Inverse Rate (${rate.currencyCode})`} required>
                  <InputNumber
                    value={rate.inverseRate}
                    onChange={(value) => handleInverseRateChange(index, value)}
                    addonBefore={rate.currencySymbol}
                  />
                </Form.Item>
              </Col>
            </Row>
          ))}
          <Text type="secondary" style={{ display: 'block', textAlign: 'center', marginBottom: 16 }}>
            {(() => {
              const record = rates.find(r => r.lastModified);
              return `Exchange Rate data updated on ${record ? new Date(record.lastModified).toLocaleString() : '-'}`;
            })()}
          </Text>
          <Form.Item style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              <SaveOutlined /> Save Changes
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default ProjectExchangeRate;