// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainLayout from './MainLayout';
import ClientList from './ClientList';
import SupplierList from './SupplierList';
import TaxRateList from './TaxRateList';
import CategoryManager from './CategoryManager';
import MaterialManager from './MaterialManager';
import LaborManager from './LaborManager';
import ModuleManager from './ModuleManager';
import ModuleCompositeManager from './ModuleCompositeManager';
import Homepage from './Homepage';
import ProjectForm from './ProjectForm';
import ProjectCreation from './ProjectCreation';
import { ConfigProvider } from 'antd';
import DistributionCenterManager from './DistributionCenterManager';
import { GoogleMapsProvider } from './GoogleMapsProvider';
import theme from './theme'; // Import your theme
import themeDark from './themeDark';
import ExpenseManager from './ExpenseManager';
import PriceListManager from './PriceListManager';
import PriceListForm from './PriceListForm';
import ProjectPriceReviewForm from './ProjectPriceReviewForm';
import TaskPage from './TaskPage';
import TransportationRateList from './TransportationRateList';
import ExpenseTypeList from './ExpenseTypeList'; 
import CurrencyManager from './CurrencyManager';
import SystemManager from './SystemManager';
import HandlingCostList from './HandlingCostList';
import DistanceTierManager from './DistanceTierManager';
import ProjectSummary from './ProjectSummary';
import EstimationDetailsForm from './EstimationDetailsForm';
import EstimationSummaryForm from './EstimationSummaryForm';

const App = () => {
  const [isDarkTheme, setIsDarkTheme] = useState(true);

  return (
    <ConfigProvider theme={isDarkTheme ? themeDark : theme}>
      <GoogleMapsProvider>
        <Router>
          <MainLayout isDarkTheme={isDarkTheme} setIsDarkTheme={setIsDarkTheme}>
            <Routes>
              <Route path="/distributioncenters" element={<DistributionCenterManager />} />
              <Route path="/clients" element={<ClientList />} />
              <Route path="/suppliers" element={<SupplierList />} />
              <Route path="/taxrates" element={<TaxRateList />} />
              <Route path="/categories" element={<CategoryManager />} />
              <Route path="/materials" element={<MaterialManager />} />
              <Route path="/labor" element={<LaborManager />} />
              <Route path="/expenses" element={<ExpenseManager />} />
              <Route path="/module" element={<ModuleManager />} />
              <Route path="/modulecomposite" element={<ModuleCompositeManager />} />
              <Route path="/about" element={<div>About Page</div>} />
              <Route path="/" element={<Homepage />} />
              <Route path="/project" element={<ProjectForm />} />
              <Route path="/projects/create" element={<ProjectForm />} />
              <Route path="projects/:projectId/edit" element={<ProjectForm />} />
              <Route path="/projects/:projectId" element={<ProjectSummary />} />
              <Route path="/estimation/:Id/summary" element={<EstimationSummaryForm />} />
              <Route path="/estimation/:Id" element={<EstimationDetailsForm />} />
              <Route path="/project/:projectId/pricereview" element={<ProjectPriceReviewForm />} />
              <Route path="/pricelists" element={<PriceListManager/>} />
              <Route path="/pricelist/:id?" element={<PriceListForm/>} />
              <Route path="/pricelist" element={<PriceListForm/>} />
              <Route path="/tasks" element={<TaskPage/>} />
              <Route path="/transportationrates" element={<TransportationRateList/>} />
              <Route path="/expensetype" element={<ExpenseTypeList />} /> 
              <Route path="/currencies" element={<CurrencyManager />} /> 
              <Route path="/systems" element={<SystemManager />} /> 
              <Route path="/handling" element={<HandlingCostList />} /> 
              <Route path="/distance" element={<DistanceTierManager />} /> 
            </Routes>
          </MainLayout>
        </Router>
      </GoogleMapsProvider>
    </ConfigProvider>
  );
};

export default App;
