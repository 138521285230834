import React, { useState, useEffect } from 'react';
import { Layout, Menu, Drawer, Button, Avatar, Typography, Badge, message, Switch } from 'antd';
import { MenuUnfoldOutlined, UserOutlined, NotificationOutlined, HomeOutlined, MoonOutlined, SunOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from './Config';
import './MainLayout.css';

const { Header, Sider, Content } = Layout;
const { Text } = Typography;

const MainLayout = ({ children, isDarkTheme, setIsDarkTheme }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [newTasksCount, setNewTasksCount] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    fetchNewTasksCount();

    const intervalId = setInterval(fetchNewTasksCount, 30000); // Fetch every 30 seconds

    return () => {
      window.removeEventListener('resize', handleResize);
      clearInterval(intervalId); // Cleanup interval on unmount
    };
  }, []);


  const fetchNewTasksCount = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/usertask/1/count`); // Replace with your API endpoint
      setNewTasksCount(response.data);
    } catch (error) {
      message.error('Failed to fetch new tasks count');
      console.error('Error fetching new tasks count:', error);
    }
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const handleMenuClick = () => {
    if (isMobile) {
      closeDrawer();
    }
  };

  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  const menuItems = [
    {
      key: 'sub1',
      icon: <UserOutlined />,
      label: 'Start',
      children: [
        { key: '1', label: <Link to="/">Home</Link> },
        { key: '2', label: <Link to="/projects/create">New Project</Link> },
      ],
    },
    {
      key: 'sub3',
      icon: <NotificationOutlined />,
      label: 'Configuration',
      children: [
        { key: '3', label: <Link to="/pricelists">Prices Lists</Link> },
        { key: '4', label: <Link to="/materials">Materials</Link> },
        { key: '5', label: <Link to="/labor">Labor</Link> },
        { key: '6', label: <Link to="/expenses">Expenses</Link> },
        { key: '7', label: <Link to="/module">Module</Link> },
        { key: '8', label: <Link to="/modulecomposite">Module Composite</Link> },
        { key: '9', label: <Link to="/clients">Clients</Link> },
        { key: '10', label: <Link to="/suppliers">Suppliers</Link> },
        { key: '11', label: <Link to="/distributioncenters">Distribution Centers</Link> },
        { key: '12', label: <Link to="/categories">Categories</Link> },
        { key: '13', label: <Link to="/transportationrates">Transportation Rates</Link> },
        { key: '14', label: <Link to="/taxrates">Tax Rates</Link> },
        { key: '15', label: <Link to="/expensetype">Expense Types</Link> },
        { key: '16', label: <Link to="/currencies">Currencies</Link> },
        { key: '17', label: <Link to="/systems">Systems</Link> },
        { key: '18', label: <Link to="/handling">Handling Costs</Link> },
        { key: '19', label: <Link to="/distance">Distance Parameters</Link> },
        {
          key: '20',
          label: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: '8px' }}>Theme</span>
              <Switch
                checked={isDarkTheme}
                onChange={toggleTheme}
                checkedChildren={<MoonOutlined />}
                unCheckedChildren={<SunOutlined />}
              />
            </div>
          ),
        },
      ],
    },
  ];

  const userInfo = (
    <div style={{ backgroundColor: '#001529', padding: '16px', color: '#fff' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Badge count={newTasksCount} size="small" style={{ marginRight: '16px' }}>
          <Link to="/tasks" style={{ color: 'white' }} onClick={closeDrawer}>
            <Avatar size={48} icon={<UserOutlined />} style={{ marginRight: '16px' }} />
          </Link>
        </Badge>
        <div style={{ textAlign: 'left' }}>
          <Text strong style={{ color: '#fff' }}>John Doe</Text>
          <br />
          <Text type="secondary" style={{ fontSize: '12px', color: '#aaa' }}>john.doe@example.com</Text>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
        <HomeOutlined style={{ marginRight: '4px', color: '#aaa', paddingLeft: "65px" }} />
        <Text style={{ color: '#fff', fontSize: '12px' }}>Organization Name</Text>
      </div>
    </div>
  );

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header className="header">
        <div className="logo">
          <Link to="/">
            <img src="/darwin.png" alt="Logo" style={{ height: '50px', paddingBottom: '16px' }} />
          </Link>
        </div>
        {isMobile ? (
          <Button
            className="menu-button"
            type="dashed"
            ghost
            onClick={showDrawer}
            style={{ marginLeft: 'auto' }}
          >
            <Badge count={newTasksCount} size="small" style={{ marginRight: '16px' }}><MenuUnfoldOutlined style={{ color: 'white' }} /></Badge>
          </Button>
        ) : (
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={['1']}
            items={[
              { key: '1', label: <Link to="/">Home</Link> },
              { key: '2', label: <Link to="/modules">Clients</Link> },
            ]}
          />
        )}
      </Header>
      <Layout>
        {!isMobile && (
          <Sider
            width={300}
            className="site-layout-background"
            collapsible
            collapsed={collapsed}
            onCollapse={toggleCollapsed}
            breakpoint="md"
            collapsedWidth="0"
          >
            {userInfo}
            <Menu mode="inline" theme="dark" defaultSelectedKeys={['1']} style={{ height: '80%', borderRight: 0 }} items={menuItems} />
          </Sider>
        )}
        {isMobile && (
          <Drawer
            placement="left"
            closable={false} // Remove the close button
            onClose={closeDrawer}
            open={drawerVisible}
            className="custom-drawer"
            width={300}
            styles={{
              header: { display: 'none' }, // Hide the header
              body: { backgroundColor: '#001529', padding: 0 } // Set background color
            }}
          >
            {userInfo}
            <Menu mode="inline" theme="dark" defaultSelectedKeys={['1']} style={{ height: '80%', borderRight: 0 }} items={menuItems} onClick={handleMenuClick} />
          </Drawer>
        )}
        <Layout style={{ padding: '0' }}>
          <div className="container">
            <Content
              className="site-layout-background"
              style={{
                padding: 0,
                paddingTop: 12,
                margin: 0,
                minHeight: 70
              }}
            >
              {children}
            </Content>
          </div>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
