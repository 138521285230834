import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // added useNavigate
import {
  Collapse,
  Space,
  Typography,
  Divider,
  Row,
  Col,
  Card,
  Statistic,
  Table,
  Progress,
  Button,
  message,
  Descriptions,
  Spin  // added Spin
} from 'antd';
import { 
  InfoCircleOutlined, 
  BuildOutlined, 
  ClusterOutlined, 
  LoadingOutlined,  // added LoadingOutlined
  SaveOutlined // new import for save icon
} from '@ant-design/icons';
import axios from 'axios';
import config from './Config';

const { Paragraph } = Typography;
const { Panel } = Collapse;

/** Example columns for each table */
const materialColumns = [
  { title: 'Material Name', dataIndex: 'materialName', key: 'materialName' },
  {
    title: 'Unit Price',
    dataIndex: 'unitPrice',
    key: 'unitPrice',
    render: (value, record) => {
      const symbol = record.currencySymbol || '$';
      return `${symbol} ${value?.toLocaleString()}`;
    },
  },
  { title: 'Quantity', dataIndex: 'quantity', key: 'quantity' },
  {
    title: 'Tax Rate',
    dataIndex: 'taxRate',
    key: 'taxRate',
    render: (value) => `${value}%`,
  },
];

const laborColumns = [
  { title: 'Labor Type', dataIndex: 'laborType', key: 'laborType' },
  {
    title: 'Hourly Rate',
    dataIndex: 'hourlyRate',
    key: 'hourlyRate',
    render: (value, record) => {
      const symbol = record.currencySymbol || '$';
      return `${symbol} ${value?.toLocaleString()}`;
    },
  },
  { title: 'Hours Required', dataIndex: 'hoursRequired', key: 'hoursRequired' },
  {
    title: 'Allowance Amount',
    dataIndex: 'allowanceAmount',
    key: 'allowanceAmount',
    render: (value, record) => {
      const symbol = record.allowanceCurrencySymbol || record.currencySymbol || '$';
      return `${symbol} ${value?.toLocaleString()}`;
    },
  },
  { title: 'Allowance Qty', dataIndex: 'allowanceQuantity', key: 'allowanceQuantity' },
];

const expenseColumns = [
  { title: 'Expense Name', dataIndex: 'expenseName', key: 'expenseName' },
  {
    title: 'Fixed Cost',
    dataIndex: 'fixedCost',
    key: 'fixedCost',
    render: (value, record) => {
      const symbol = record.currencySymbol || '$';
      return `${symbol} ${value?.toLocaleString()}`;
    },
  },
  {
    title: 'Total Cost',
    dataIndex: 'totalCost',
    key: 'totalCost',
    render: (value, record) => {
      // Some expenses might have a different "totalCostCurrencySymbol", but if not present, fallback:
      const symbol =
        record.totalCostCurrencySymbol || record.currencySymbol || '$';
      return `${symbol} ${value?.toLocaleString()}`;
    },
  },
  { title: 'Quantity', dataIndex: 'quantity', key: 'quantity' },
];

const EstimationSummaryForm = ({ setLoading }) => {
  const { Id: estimationId } = useParams();
  const navigate = useNavigate(); // new instance
  const [data, setData] = useState(null);
  const [profitMargin, setProfitMargin] = useState(0);
  const [estimationRecord, setEstimationRecord] = useState(null); // new state

  const fetchProjectDetails = async () => {
    setLoading?.(true);
    try {
      const response = await axios.get(`${config.apiUrl}/EstimationDetails/${estimationId}/summary`);
      setData(response.data);
      setProfitMargin(response.data?.profitMargin ?? 0);
    } catch (error) {
      console.error('Error fetching project details:', error);
      message.error('Failed to load project details');
    } finally {
      setLoading?.(false);
    }
  };

  // new function to fetch full estimation record
  const fetchEstimationRecord = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/ProjectEstimations/${estimationId}`);
      setEstimationRecord(response.data);
    } catch (error) {
      console.error('Error fetching estimation record:', error);
      message.error('Failed to load estimation record');
    }
  };

  // new function to handle saving the estimation
  const handleSave = async () => {
    if (!estimationRecord) return;
    const updatedRecord = { ...estimationRecord, profitMargin };
    setLoading?.(true);
    try {
      await axios.put(`${config.apiUrl}/ProjectEstimations/${estimationId}`, updatedRecord);
      message.success('Estimation saved successfully');
    } catch (error) {
      console.error('Error saving estimation:', error);
      message.error('Error saving estimation');
    } finally {
      setLoading?.(false);
    }
  };

  useEffect(() => {
    fetchProjectDetails();
    fetchEstimationRecord(); // fetch full estimation record
  }, [estimationId]);

  if (!data) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} tip="Loading Estimation Summary..." fullscreen/>
      </div>
    );
  }

  const {
    currencySymbol = '$',
    totalCost = 0, // no longer used for grand total calculation
    totalMaterials = 0,
    totalLabor = 0,
    totalExpenses = 0,
    totalAllowances = 0,
    totalHandling = 0,
    totalTaxes = 0,
    distance = 0,
    modules = [],
    modulesComposite = [],
    parentLessCosts,
  } = data;

// New calculations based on material, labor, and expense totals.
  const baseCost = totalMaterials + totalLabor + totalExpenses;
  const computedProfit = (baseCost * profitMargin) / 100;
  const grandTotal = baseCost + computedProfit;

  // --- 1) Calculate System distribution (for progress circles) ---
  // (We're still locally summing module.total or detail.module.total for systemName.)
  const systemCosts = {};
  modules.forEach((m) => {
    const sysName = m.systemName || 'No System';
    if (!systemCosts[sysName]) {
      systemCosts[sysName] = 0;
    }
    systemCosts[sysName] += m.total || 0;
  });

  modulesComposite.forEach((comp) => {
    comp.compositeDetails?.forEach((detail) => {
      const sysName = detail.module?.systemName || 'No System';
      if (!systemCosts[sysName]) {
        systemCosts[sysName] = 0;
      }
      systemCosts[sysName] += detail.module?.total || 0;
    });
  });

  const totalSystemCost = Object.values(systemCosts).reduce((acc, val) => acc + val, 0);
  const systemCostArray = Object.entries(systemCosts).map(([systemName, value]) => {
    const percentage = totalSystemCost > 0 ? (value / totalSystemCost) * 100 : 0;
    return {
      systemName,
      percentage: percentage.toFixed(1),
    };
  });

  // Compute totalProfit from the margin
  const totalProfit = (totalCost * profitMargin) / 100;

  return (
    <div style={{ minHeight: '50vh', display: 'flex', flexDirection: 'column' }}>
      {/* Intro text */}
      <Paragraph>
        <InfoCircleOutlined style={{ marginRight: 8 }} />
        Below is your project's read-only cost data, plus system distribution &amp; profit margin.
      </Paragraph>
      <Divider />

      {/* (A) Systems area (progress circles) */}
      <Row gutter={16} justify="center">
        {systemCostArray.map((system) => (
          <Col key={system.systemName} span={6} style={{ textAlign: 'center' }}>
            <Progress
              type="circle"
              percent={parseFloat(system.percentage)}
              size={80}
              strokeColor="#88b04d"
            />
            <div style={{ marginTop: 8 }}>
              {system.systemName} ({system.percentage}%)
            </div>
          </Col>
        ))}
      </Row>
      <Divider />

      {/* (B) Modules & Composites in accordions with read-only tables */}
      <Collapse style={{ marginBottom: 20 }}>
        {/* Modules */}
        {modules.map((module) => (
          <Panel
            header={
              <Space style={{ justifyContent: 'space-between', width: '100%' }}>
                <span><BuildOutlined /> {module.moduleName}</span>
                <span>
                  Total: {currencySymbol}{module.total?.toLocaleString()} (Qty: {module.quantity})
                </span>
              </Space>
            }
            key={`module_${module.moduleId}`}
          >
            <h4>Materials</h4>
            <Table
              dataSource={module.moduleMaterials}
              columns={materialColumns}
              rowKey="projectMaterialId"
              pagination={false}
            />

            <h4 style={{ marginTop: 16 }}>Labor</h4>
            <Table
              dataSource={module.moduleLabors}
              columns={laborColumns}
              rowKey="projectLaborId"
              pagination={false}
            />

            <h4 style={{ marginTop: 16 }}>Expenses</h4>
            <Table
              dataSource={module.moduleExpenses}
              columns={expenseColumns}
              rowKey="projectExpenseId"
              pagination={false}
            />
          </Panel>
        ))}

        {/* Composites */}
        {modulesComposite.map((composite) => (
          <Panel
            header={
              <Space style={{ justifyContent: 'space-between', width: '100%' }}>
                <span><ClusterOutlined /> {composite.compositeName}</span>
                <span>
                  Total: {currencySymbol}{composite.totalCost?.toLocaleString()} (Qty: {composite.quantity})
                </span>
              </Space>
            }
            key={`composite_${composite.projectModuleCompositeId}`}
          >
            {composite.compositeDetails?.map((detail) => (
              <Collapse
                style={{ marginBottom: 16 }}
                key={`detail_${detail.moduleId}_${detail.moduleCompositeDetailId}`}
                bordered={false}
              >
                <Panel
                  header={
                    <Space style={{ justifyContent: 'space-between', width: '100%' }}>
                      <span><BuildOutlined /> {detail.moduleName}</span>
                      <span>
                        Total: {currencySymbol}{detail.module?.total?.toLocaleString()}
                        (Qty: {detail.quantity})
                      </span>
                    </Space>
                  }
                  key={`panel_detail_${detail.moduleId}`}
                >
                  <h4>Materials</h4>
                  <Table
                    size='small'
                    dataSource={detail.module?.moduleMaterials}
                    columns={materialColumns}
                    rowKey="projectMaterialId"
                    pagination={false}
                  />

                  <h4 style={{ marginTop: 16 }}>Labor</h4>
                  <Table
                    size='small'
                    dataSource={detail.module?.moduleLabors}
                    columns={laborColumns}
                    rowKey="projectLaborId"
                    pagination={false}
                  />

                  <h4 style={{ marginTop: 16 }}>Expenses</h4>
                  <Table
                    size='small'
                    dataSource={detail.module?.moduleExpenses}
                    columns={expenseColumns}
                    rowKey="projectExpenseId"
                  />
                </Panel>
              </Collapse>
            ))}
          </Panel>
        ))}

        {/* Parent-less costs, if any */}
        {parentLessCosts && parentLessCosts.total > 0 && (
          <Panel header="Unassigned Costs" key="parentLessCosts">
            <h4>Materials</h4>
            <Table
              size='small'
              dataSource={parentLessCosts.moduleMaterials}
              columns={materialColumns}
              rowKey="projectMaterialId"
              pagination={false}
            />
            <h4 style={{ marginTop: 16 }}>Labor</h4>
            <Table
              size='small'
              dataSource={parentLessCosts.moduleLabors}
              columns={laborColumns}
              rowKey="projectLaborId"
              pagination={false}
            />
            <h4 style={{ marginTop: 16 }}>Expenses</h4>
            <Table
              size='small'
              dataSource={parentLessCosts.moduleExpenses}
              columns={expenseColumns}
              rowKey="projectExpenseId"
            />
          </Panel>
        )}
      </Collapse>

      {/* (C) Statistics cards: distance, total materials, labor, expenses, profit margin, profit, and grand total */}
      <Row gutter={16}>
        <Col xs={24} sm={8} style={{ marginBottom: 16 }}>
          <Card>
            <Statistic
                title="Total Materials"
                value={totalMaterials}
                precision={2}
                valueStyle={{ color: '#3f8600' }}
                prefix={currencySymbol}
              />
          </Card>
        </Col>
        <Col xs={24} sm={8} style={{ marginBottom: 16 }}>
          <Card>
          <Statistic
              title="Total Expenses"
              value={totalExpenses}
              precision={2}
              valueStyle={{ color: '#3f8600' }}
              prefix={currencySymbol}
            />
          </Card>
        </Col>
        <Col xs={24} sm={8} style={{ marginBottom: 16 }}>
          <Card>
            <Statistic
              title="Total Labor"
              value={totalLabor}
              precision={2}
              valueStyle={{ color: '#3f8600' }}
              prefix={currencySymbol}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={16} >
        <Col xs={24} sm={8} style={{ marginBottom: 16 }}>

        <Descriptions column={1} size="small" bordered>
            <Descriptions.Item label="Taxes">{currencySymbol}{totalTaxes.toFixed(2)}</Descriptions.Item>
            <Descriptions.Item label="Allowances">{currencySymbol}{totalAllowances.toFixed(2)}</Descriptions.Item>
            <Descriptions.Item label="Handling Costs">{currencySymbol}{totalHandling.toFixed(2)}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col xs={24} sm={8} style={{ marginBottom: 16 }}>

        <Card>
            <Statistic
                title="Driving Distance (km)"
                value={distance}
                precision={2}
                valueStyle={{ color: '#000' }}
              />
          </Card>
        </Col>
        <Col xs={24} sm={8} style={{ marginBottom: 16 }}>
        <Card>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Statistic
                key={`profit-${profitMargin}`} // Force remount when profitMargin changes.
                className="smooth-update" // CSS class for animation.
                title="Profit Margin"
                value={profitMargin}
                precision={1}
                suffix="%"
              />
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                <Button
                  size="small"
                  onClick={() => setProfitMargin((pm) => Math.min(pm + 1, 100))}
                >
                  +
                </Button>
                <Button
                  size="small"
                  onClick={() => setProfitMargin((pm) => Math.max(pm - 1, 0))}
                >
                  -
                </Button>
              </div>
            </div>
          </Card>
        </Col>
      </Row>

      <Row gutter={16} >
        <Col xs={24} sm={8} style={{ marginBottom: 16 }}>
          <Card>
            <Statistic
              key={`totalProfit-${computedProfit}`} // Force remount on computedProfit change.
              className="smooth-update" // CSS class for animation.
              title="Total Profit"
              value={computedProfit} // updated calculation
              precision={2}
              prefix={currencySymbol}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={8}>
          <Card>
            <Statistic
              key={`grandTotal-${grandTotal}`} // Force remount on grandTotal change.
              className="smooth-update" // CSS class for animation.
              title="Grand Total"
              value={grandTotal} // updated calculation: base cost plus profit
              precision={2}
              valueStyle={{ color: '#000' }}
              prefix={currencySymbol}
            />
          </Card>
        </Col>
      </Row>

      {/* New Buttons Row */}
      <Row justify="end" style={{ marginTop: 20 }}>
        <Button 
          type="dashed" 
          style={{ marginRight: 8 }} 
          onClick={() => navigate(`/projects/${data?.projectId}`)}>
          Back to Project
        </Button>
        <Button type="primary" icon={<SaveOutlined />} onClick={handleSave}>
          Save Changes
        </Button>
      </Row>
    </div>
  );
};

export default EstimationSummaryForm;
