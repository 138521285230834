
// src/themeDark.js
const themeDark = {
  token: {
    brandColor: '#5b6d92',
    colorPrimary: '#5b6d92',
    colorSecondary: '#a2b3c2',
    colorBgBase: '#1e1e1e',
    colorTextBase: '#FAF9F6',
    colorLink: '#D18266',
    borderRadius: 8,
    Button: {
      primaryShadow: "none"
    },
    // Optional: Add extra tokens for more nuanced color handling
    colorBgContainer: '#2a2a2a',
    colorTextHeading: '#ffffff',
    colorBorder: '#3f3f3f',
    Tooltip: {
      colorTextLightSolid: 'rgba(30,30,30,0.88)',
      backgroundColor: '#FAF9F6'
    }
  }
};

export default themeDark;