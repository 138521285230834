import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Typography, InputNumber } from 'antd';
import axios from 'axios';
import config from './Config';

const { Title } = Typography;
const { Option } = Select;

const EstimationForm = ({ form, onSubmit, onCancel, isEditing, selectedEstimation }) => {
  const [distributionCenters, setDistributionCenters] = useState([]);

  useEffect(() => {
    // Fetch distribution centers
    const fetchDistributionCenters = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/distributionCenters`);
        setDistributionCenters(response.data);
      } catch (error) {
        console.error('Error fetching distribution centers:', error);
      }
    };

    fetchDistributionCenters();

    if (isEditing && selectedEstimation) {
      form.setFieldsValue({
        description: selectedEstimation.description,
        profitMargin: selectedEstimation.profitMargin,
        distributionCenterId: selectedEstimation.distributionCenterId,
      });
    } else {
      form.resetFields();
    }
  }, [form, isEditing, selectedEstimation]);

  return (
    <div style={{ width: '90%' }}>
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Please enter the description' }]}
        >
          <Input placeholder="Please enter the description" />
        </Form.Item>
        <Form.Item
          name="profitMargin"
          label="Profit Margin (%)"
          rules={[{ required: true, message: 'Please enter the profit margin' }]}
        >
          <InputNumber placeholder="Please enter the profit margin" />
        </Form.Item>
        <Form.Item
          name="distributionCenterId"
          label="Distribution Center"
          rules={[{ required: true, message: 'Please select a distribution center' }]}
        >
          <Select placeholder="Please select a distribution center">
            {distributionCenters.map(center => (
              <Option key={center.distributionCenterId} value={center.distributionCenterId}>{center.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <div style={{ textAlign: 'right', marginTop: 16 }}>
            <Button onClick={onCancel} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EstimationForm;
